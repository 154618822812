import React from 'react'
import continuedProjectsData from '../../data/projectsContinuedData'
import ContinuedProjects from './ContinuedComponents/ContinuedProjects'

export default function ContinuedAllProjects() {
    return (
        <section className="uk-section">
            <div className="uk-container uk-container-xlarge">
                {continuedProjectsData.map((continuedProjectsCard) => (
                <ContinuedProjects key={continuedProjectsCard._id} continuedProjectsCard={continuedProjectsCard}></ContinuedProjects>
                ))}
            </div>
        </section>
    )
}
