import React from 'react'
import ContinuedAllProjects from '../components/Continued/ContinuedAllProjects'
import ContinuedBanner from '../components/Continued/ContinuedBanner'
import ContinuedIntroduction from '../components/Continued/ContinuedIntroduction'

export default function ContinuedScreen() {
    return (
        <>
            <ContinuedBanner/>
            <ContinuedIntroduction/>
            <ContinuedAllProjects/>
        </>
    )
}
