import React from 'react'
import { Link } from 'react-router-dom';
import policy from "../../assets/homepolicy.png";

export default function HomePolicy() {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center uk-flex-last uk-flex-first@m">
                        <div className="uk-padding">
                            <div className="uk-h2 uk-text-uppercase uk-margin-small uk-light uk-text-bold">KALİTE POLİTİKAMIZ</div>
                            <p className="uk-light uk-margin-remove">Bizim için güvenilir ve dürüst olmak her şeyden önce gelir, müşterilerimizle ve tedarikçilerimizle dürüst ve ahlaki bir işbirliği içinde oluruz. Uzun dönemli ve kalıcı ilişkiler bizim için önemlidir. İşlerimizi taahhüd ettiğimiz zamanda ve verilen sözlere uygun olarak doğru bir şekilde yaparız.</p>
                            <p className="uk-margin uk-light">İşini bilinçli yapan, çevresini sorgulayan, çözüm bulan, üstün performans gösteren ve hedefleri doğrultusunda insiyatif kullanabilen kişilerle çalışır, başarımızı paylaşırız.</p>
                            <Link className="uk-button uk-button-primary" to="/quality">Devamını Oku</Link>
                        </div>
                    </div>
                    <div className="grid-border uk-flex-first uk-flex-last@m">
                        <img src={policy} alt="" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-1"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
