import React from 'react'
import { Link } from 'react-router-dom';

export default function ContinuedCard(props) {
    const {continuedCard} = props;
    return (
        <li className="grid-border" key={continuedCard._id}>
            <div className="uk-panel">
                <Link to="/continued" className='uk-link-reset'>
                    <div className="uk-card">
                        <div className="uk-card-media-top">
                            <img src={`${process.env.PUBLIC_URL}/${continuedCard.image}`}  alt={`done-projects-${continuedCard.id}`} class="uk-width-1-1" data-uk-img="loading:lazy;" />
                        </div>
                        <div className="uk-remove-margin uk-card-body">
                            <div className="uk-card-title uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-margin-small-bottom">
                                <div className="uk-text-bold uk-h4 uk-light uk-text-center">{continuedCard.header}</div>
                            </div>
                            {/*
                            <div className="uk-padding-remove uk-margin-small-top uk-margin-remove-bottom">
                                <small>Devam Eden Proje</small>
                            </div>
                            */}
                        </div>
                    </div>
                </Link>
            </div>
        </li>
    )
}
