import React from 'react'
import HomeReferance from '../components/Home/HomeReferance'
import PolicyBanner from '../components/Policy/PolicyBanner'
import PolicyCertificate from '../components/Policy/PolicyCertificate'
import PolicyNature from '../components/Policy/PolicyNature'
import PolicyQuality from '../components/Policy/PolicyQuality'

export default function PolicyScreen() {
    return (
        <>
            <PolicyBanner/>
            <PolicyQuality />
            <PolicyNature />
            <PolicyCertificate />
            <HomeReferance/>
        </>
    )
}
