import React from 'react'
import aboutPolicy from "../../assets/about-policy.png";

export default function QualityPolicy() {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center uk-flex-last">
                        <div className="uk-padding">
                            <div className="uk-h2 uk-text-uppercase uk-margin-small uk-light uk-text-bold">FİRMA POLİTİKAMIZ</div>
                            <p className="uk-margin-small uk-light">Değerli İş Ortaklarımız ve Müşterilerimiz;</p>
                            <p className="uk-light uk-margin-remove">İnşaat ve Mimarlık alanında, alanında profesyonel teknik kadromuzla, müşterilerimizin ihtiyaçlarını doğru tespit ederek memnuniyetiniz odaklı, kaliteden taviz vermeden ülkemize hizmet etmekteyiz.</p>
                            <p className="uk-margin-small uk-light">İnşaat ve Mimarlık alanında, yurt içi ve yurt dışı projelerde, hizmet verdiğimiz tüm alanlarda, rekabet gücümüzü arttırarak, ülke hedeflerimize katkı sağlayarak, müşteri ve kalite odaklı mühendislik hizmetlerini hız kesmeden, vermeye devam ediyoruz.</p>
                            <p className="uk-margin-small uk-light">Alanında, istikrarlı yükselişimizle, sektörde kalite ve uygun fiyat politikası ile itibar ve güvenilirlik konusunda örnek teşkil etmeye devam ederken; yüksek çalışma tempomuz ile stratejilerimizi uygulamaya, uzman personel yapısı ile önümüzdeki yıllarda da sektöre ve ülke ekonomisine değer katmaya, taahhütlerimizi yerine getirmeye, sektörde fark yaratmaya ve takip edilmeye devam edeceğiz.</p>
                            <p className="uk-margin-small uk-light">Hızla büyüyen Düet İnşaat & Mimarlık ailesinin yönetim kurulu başkanlığını yapmaktan gurur ve mutluluk duyuyor, bu başarılara ulaşmamızda bize destek veren ve emeği geçen tüm çalışma arkadaşlarıma, Ortaklarımıza ve Tedarikçi Firmalarımıza teşekkürlerimi sunuyorum.</p>
                        </div>
                    </div>
                    <div className="grid-border uk-flex-first">
                        <img src={aboutPolicy} alt="" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-1"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
