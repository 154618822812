/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

export default function HomeSlider() {
    return (
        <section id="homeSlider" class="uk-section uk-background-cover uk-background-fixed uk-cover-container uk-position-relative home-banner" data-uk-height-viewport="offset-top:true;">
            <div className="uk-container">
                <div className="uk-position-center uk-position-small uk-text-center uk-light">
                    <h1 className="uk-margin-remove uk-text-bold home-banner-title" data-uk-parallax="target: #homeSlider; start: 100%; end: 100%; y: 500;">YÜKSEK MÜHENDİSLİK VE KAPASİTE</h1>
                </div>
            </div>
        </section>
    )
}