const doneProjectsData = [
    {
        id: 1,
        header: `SVR GAYRIMENKUL YATIRIM A.Ş. – BUSINESS İSTANBUL PROJESİ
        240.000 M2`,
        totalImage: 16,
        imageUrlTitle: 'svr',
        image: 'assets/homeProjects/doned/svr.jpg',
        description: `Projede, Kule A'da 190 adet ofis, Kule B'de 189 adet ofis, Kule C'de14 adet ofis olmak üzere toplam 393 adet ofis bulunuyor. Ofisler, 79, 83 ve 320 metrekare büyüklüğe sahip. Modülasyonlar ve mimari proje esnekliği sayesinde kat bazında maksimum 1250 metrekareye kadar farklı kullanım alanı alternatifleri sunuyor. İstanbul’un tam kalbinde, yeni açılacak Avrasya Tüneli’nin çıkış noktasında yer alan Business İstanbul projesi E-5'e cepheli konumuyla dikkat çekiyor. Ofislerdeki kat yüksekliğinin 3,85 metre olarak tasarlanması da projeye ayrı bir ferahlık katıyor. Böylece hayatın büyük kısmının geçtiği çalışma ortamlarına, metrekare ile birlikte metreküp kavramı da kazandırılmış oluyor. 550 milyon TL’lik yatırımla hayata geçirilen proje, çalışanlar için hayatı kolaylaştıran çok sayıda özelliği bir arada sunacak.`,
    },
    {
        id: 2,
        header: `HUKUKÇULAR TOWERS – KARTAL
        32.000 M2`,
        totalImage: 1,
        imageUrlTitle: 'hukukcular',
        image: 'assets/homeProjects/doned/hukukcular.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 3,
        header: `MERMERLER PLAZA
        35.000 M2`,
        totalImage: 1,
        imageUrlTitle: 'mermerler',
        image: 'assets/homeProjects/doned/mermerler.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 4,
        header: `PANA YAPI KONUT A.Ş. – BROOKLYN PARK PROJESİ
        220.000 M2`,
        totalImage: 2,
        imageUrlTitle: 'pana',
        image: 'assets/homeProjects/doned/pana.jpg',
        description: `1961 yılından bu yana birçok başarıya imza atan Selimoğlu Grup ve 2010 yılında faaliyete geçerek kısa sürede başarılı projelere hayat veren Pana Yapı’nın ortak girişiminin ilk projesi Brooklyn Park, yeni Fikirtepe'nin en yüksek noktasında yer alıyor. 14 bin 500 metrekare arsa üzerine inşa edilen proje, 731 daire ile 11 mağazadan oluşuyor. Brooklyn Park'da 1+1, 2+1, 3+1 ve 4+1 daire seçenekleri, 69 metrekare ile 220 metrekare arasındaki büyüklüklerle sunuluyor. Projede, 1+1 ve 2+1 daire tipleri oransal olarak daha fazla yer alıyor.`,
    },
    {
        id: 5,
        header: `KURİŞ KULE – KARTAL
        40.000 M2`,
        totalImage: 1,
        imageUrlTitle: 'kuris',
        image: 'assets/homeProjects/doned/kuris.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 6,
        header: `İNTES İNŞAAT A.Ş. – IŞIK PLASTİK II.FABRİKA İNŞAATI PROJESİ
        25.000 M2`,
        image: 'assets/homeProjects/doned/intes.jpg',
        totalImage: 19,
        imageUrlTitle: 'intes',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 7,
        header: `DUMES İNŞAAT TUR. A.Ş. – BAŞAKŞEHİR KURU GIDA HALİ PROJESİ
        130.000 M2`,
        totalImage: 8,
        imageUrlTitle: 'dumes',
        image: 'assets/homeProjects/doned/dumes.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 8,
        header: `REYSAŞ G.Y.O. – ÇAYIROVA 9 PROJESİ
        105.000 M2`,
        totalImage: 5,
        imageUrlTitle: 'reysas-cayirova-9',
        image: 'assets/homeProjects/doned/reysas-cayirova-9.jpg',
        description: `Reysaş GYO, 246.000.001 TL sermayesi ile Türkiye’nin depoculuk alanında uzmanlaşmış ilk ve tek Gayrimenkul Yatırım Ortaklığıdır. Depoculuk alanında gayrimenkullere ve gayrimenkul projelerine yatırım yapmak, geliştirmek ve bu yerleri uzun vadeli kiralamak üzere kurulmuş bir portföy yönetim şirketidir. Tamamı dünya standartlarında geliştirilimiş depolarıyla Reysaş GYO, Türkiye'nin ilk ve tek depo geliştirmeye odaklanmış gayrimenkul yatırım ortaklığıdır. Bu pozisyonu ile ülkemiz gayrimenkul piyasasına yeni bir soluk getirerek farklılık kazandıran Reysaş GYO’nun öncelikli hedefi, hissedarlarımız için paylaşılabilir ve büyüyen değerler yaratmaktır.`,
    },
    {
        id: 9,
        header: `REYSAŞ G.Y.O – ÇAYIROVA 10 LOJİSTİK DEPO YAPIMI
        30.000 M2`,
        totalImage: 0,
        imageUrlTitle: 'reysas-cayirova-10',
        image: 'assets/homeProjects/doned/reysas-cayirova-10.jpg',
        description: `Reysaş GYO, 246.000.001 TL sermayesi ile Türkiye’nin depoculuk alanında uzmanlaşmış ilk ve tek Gayrimenkul Yatırım Ortaklığıdır. Depoculuk alanında gayrimenkullere ve gayrimenkul projelerine yatırım yapmak, geliştirmek ve bu yerleri uzun vadeli kiralamak üzere kurulmuş bir portföy yönetim şirketidir..`,
    },
    {
        id: 10,
        header: `EKŞİOĞLU İNŞAAT – KOCAELİ KİRAZLIBAHÇE TOPLU KONUT YAPIMI
        448 ADET KONUT`,
        totalImage: 1,
        imageUrlTitle: 'eksioglu',
        image: 'assets/homeProjects/doned/eksioglu.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 11,
        header: `MARMARA – SIEGENER GALVANİZLEME FABRİKASI
        35.000 M2`,
        totalImage: 1,
        imageUrlTitle: 'marmara',
        image: 'assets/homeProjects/doned/marmara.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 12,
        header: `MALTEPE ÜNİVERSİTESİ SPOR KOMPLEKSİ
        30.000 M2`,
        totalImage: 6,
        imageUrlTitle: 'maltepe',
        image: 'assets/homeProjects/doned/maltepe.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 13,
        header: `İNTEK YAPI – DÜZCE TOPLU KONUT PROJESİ
        442 KONUT`,
        totalImage: 10,
        imageUrlTitle: 'intek-duzce',
        image: 'assets/homeProjects/doned/intek-duzce.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 14,
        header: `SOYKAYA İNŞAAT – KAYAKENT EVLERİ
        550 ADET`,
        totalImage: 7,
        imageUrlTitle: 'soykaya-kayakent',
        image: 'assets/homeProjects/doned/soykaya-kayakent.jpg',
        description: `Kocaeli İli , Yuvam Mahallesinde 2500 mt mesafede 20.000 m2 lik araziye 196 Konut , Ticaret Merkezi , Havuz , Açık Spor Salonları , Çocuk Parkları olan yaşam alanı yapılması işi.`,
    },
    {
        id: 15,
        header: `SOYKAYA İNŞAAT – KOCAELİ İLİMTEPE KONUTLARI
        268 ADET`,
        totalImage: 8,
        imageUrlTitle: 'soykaya-kocaeli',
        image: 'assets/homeProjects/doned/soykaya-kocaeli.jpg',
        description: `Kocaeli İli , Körfez İlçesi , İlimtepe mevkiinde yapılan 2.000 konutluk deprem konutlarının 340 adetlik kısmında yer alan yaşam alanı yapılması işi.`,
    },
    {
        id: 16,
        header: `SARIYER PANORAMA LİFE
        25.000 M2`,
        totalImage: 1,
        imageUrlTitle: 'sariyer-panaroma',
        image: 'assets/homeProjects/doned/sariyer-panaroma.jpg',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 17,
        header: 'AKSA AİRJET İPLİK FABRİKASI – YALOVA',
        image: 'assets/homeProjects/doned/aksa.jpg',
        imageUrlTitle: 'aksa',
        totalImage: 4,
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
]

export default doneProjectsData;