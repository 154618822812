import React from 'react'
import { Link } from 'react-router-dom';

export default function DoneCard(props) {
    const {doneCard} = props;
    return (
        <li className="grid-border" key={doneCard._id}>
            <div className="uk-panel">
                <Link to="/doned" className="uk-link-reset">
                    <div className="uk-card">
                        <div className="uk-card-media-top">
                            <img src={`${process.env.PUBLIC_URL}/${doneCard.image}`}  alt={`done-projects-${doneCard.id}`} class="uk-width-1-1" data-uk-img="loading:lazy;" />
                        </div>
                        <div className="uk-remove-margin uk-card-body">
                            <div className="uk-card-title uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-margin-small-bottom">
                                <div className="uk-text-bold uk-h4 uk-light uk-text-center">{doneCard.header}</div>
                            </div>
                            {/*
                            <div className="uk-padding-remove uk-margin-small-top uk-margin-remove-bottom">
                                <small>Tamamlanan Proje</small>
                            </div>
                            */}
                        </div>
                    </div>
                </Link>
            </div>
        </li>
    )
}
