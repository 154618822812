/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../assets/logo-white.png";

export default function Navbar({toggle}) {
    return (
        <header data-uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky uk-navbar-transparent; cls-inactive: uk-navbar-transparent; top: 60" className="uk-sticky">
            <div className="uk-position-absolute uk-width-1-1 uk-position-z-index header">
                <nav className="uk-navbar-container uk-navbar-sticky uk-navbar-transparent" data-uk-navbar>
                    <div className="uk-navbar-left">
                        <div className="uk-navbar-item">
                            <Link to="/" className="uk-link-reset"><img src={logo} alt="logo" data-uk-img className="navbar-logo"/></Link>
                        </div>
                    </div>
                    <div className="uk-navbar-right uk-hidden@m">
                        <a className="uk-navbar-toggle" data-uk-navbar-toggle-icon href="#" onClick={toggle}></a>
                    </div>
                    <div className="uk-navbar-right uk-visible@m">
                        <ul className="uk-navbar-nav uk-text-bold">
                            <li className="uk-navbar-item">
                                <Link to="/">ANASAYFA</Link>
                            </li>
                            <li className="uk-navbar-item">
                                <Link to="/about">HAKKIMIZDA</Link>
                            </li>
                            <li className="uk-navbar-item">
                                <Link to="/quality">KALİTE POLİTİKAMIZ</Link>
                            </li>
                            <li className="uk-navbar-item">
                                <Link to="/continued">DEVAM EDEN PROJELER</Link>
                            </li>
                            <li className="uk-navbar-item">
                                <Link to="/doned">TAMAMLANAN PROJELER</Link>
                            </li>
                            <li className="uk-navbar-item">
                                <Link to="/contact">İLETİŞİM</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div id="offcanvas-nav" data-uk-offcanvas="overlay: true; flip:true; esc-close:false;">
                    <button className="uk-offcanvas-close uk-text-large uk-light" type="button" data-uk-close></button>
                    <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                        <ul className="uk-nav uk-nav-default uk-nav-center uk-margin-auto-vertical uk-text-large">
                            <li><Link to="/">ANASAYFA</Link></li>
                            <li><Link to="/about">HAKKIMIZDA</Link></li>
                            <li><Link to="/quality">KALİTE POLİTİKAMIZ</Link></li>
                            <li><Link to="/continued">DEVAM EDEN PROJELER</Link></li>
                            <li><Link to="/doned">TAMAMLANAN PROJELER</Link></li>
                            <li><Link to="/contact">İLETİŞİM</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}
