import React from 'react'
import ContactBanner from '../components/Contact/ContactBanner'
import ContactDescripton from '../components/Contact/ContactDescripton'
import ContactForm from '../components/Contact/ContactForm'
import ContactMap from '../components/Contact/ContactMap'

export default function ContactScreen() {
    return (
        <>
            <ContactBanner/>
            <ContactMap/>
            <ContactForm/>
            <ContactDescripton/>
        </>
    )
}
