import React from 'react'
import quality from "../../assets/policy-quality.jpg";

export default function PolicyQuality() {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center uk-flex-last">
                        <div className="uk-padding">
                            <div className="uk-h2 uk-margin-small uk-light uk-text-bold">KALİTE POLİTİKAMIZ</div>
                            <p className="uk-margin-small uk-light">Bizim için güvenilir ve dürüst olmak her şeyden önce gelir, müşterilerimizle ve tedarikçilerimizle dürüst ve ahlaki bir işbirliği içinde oluruz. Uzun dönemli ve kalıcı ilişkiler bizim için önemlidir. İşlerimizi taahhüd ettiğimiz zamanda ve verilen sözlere uygun olarak doğru bir şekilde yaparız.</p>
                            <p className="uk-margin-small uk-light">İşini bilinçli yapan, çevresini sorgulayan, çözüm bulan, üstün performans gösteren ve hedefleri doğrultusunda insiyatif kullanabilen kişilerle çalışır, başarımızı paylaşırız.</p>
                            <p className="uk-margin-small uk-light">Hızla gelişen teknoloji ve pazar koşullarına göre hareket kabiliyetimizi üst noktalarda tutup, her koşulda yoğun rekabet ortamı içerisinde yerimizi koruruz.Pazarı sürekli kontrol edip, tedarikçilerimizle işbirliği içerisinde, en iyi olana odaklı olarak kendimizi sürekli geliştiririz. Değişime her zaman açığızdır.</p>
                            <p className="uk-margin-small uk-light">Çalışanlarımızın kişisel gelişimi ve eğitimleri ile ilgilenip onları, kendilerini takımın bir parçası olarak görmelerini sağlarız.Üretimden sevkiyata kadar tüm aşamalarda müşteri odaklı bir kalite ve hizmet prensibi içerisinde müşteri memnuniyetini hedef alan kalite yaklaşımını kabul ederiz.</p>
                            <p className="uk-margin-small uk-light uk-margin-remove-bottom">Mükemmel olanı üretip, mükemmel bir hizmet anlayışı içerisinde çalışmalarımızın devamlılığını sağlamak, bunu da uluslararası kabul edilebilirlik şartları çerçevesinde belgelendirmek bizim için önemlidir.</p>
                        </div>
                    </div>
                    <div className="grid-border uk-flex-first">
                        <img src={quality} alt="quality" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-1"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
