import React from 'react'
import DonedAllProjects from '../components/Doned/DonedAllProjects'
import DonedBanner from '../components/Doned/DonedBanner'
import DonedIntroduction from '../components/Doned/DonedIntroduction'

export default function DonedScreen() {
    return (
        <>
            <DonedBanner/>
            <DonedIntroduction/>
            <DonedAllProjects/>
        </>
    )
}
