const continuedProjectsData = [
    {
        id: 1,
        header: 'MİMAROBA PROJESİ - BÜYÜKÇEKMECE',
        image: 'assets/homeProjects/continued/mimaroba.jpg',
        totalImage: 4,
        imageUrlTitle: 'mimaroba',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 2,
        header: 'BOSTANCI',
        image: 'assets/homeProjects/continued/bostanci.jpg',
        totalImage: 6,
        imageUrlTitle: 'bostanci',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 3,
        header: 'Çerkezköy Ticaret Merkezi & Oto Galericiler Sitesi',
        image: 'assets/homeProjects/continued/ctm.jpg',
        totalImage: 9,
        imageUrlTitle: 'ctm',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 4,
        header: 'Çorlu Ulus Residence',
        image: 'assets/homeProjects/continued/ulus.jpg',
        totalImage: 5,
        imageUrlTitle: 'ulus',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
    {
        id: 5,
        header: 'Tepeören Projesi',
        image: 'assets/homeProjects/continued/tepeoren.jpg',
        totalImage: 16,
        imageUrlTitle: 'tepeoren',
        description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quidem libero laudantium quia quis dicta incidunt maiores maxime, totam natus cupiditate velit aspernatur aliquid cum quaerat mollitia numquam, recusandae placeat minima dolores. Quod provident quas, minima inventore sit tenetur voluptatem porro numquam eligendi praesentium accusantium ducimus ratione voluptates magni amet alias mollitia omnis deleniti. Eligendi optio enim culpa hic exercitationem quidem tempore dolor quo tenetur quod quibusdam id iusto assumenda omnis vel ad dolorum, porro adipisci blanditiis magnam libero eaque, unde doloremque. Atque quisquam nemo, saepe, cumque, porro officia corporis sapiente sunt exercitationem expedita reiciendis suscipit voluptate adipisci consectetur rerum.`,
    },
]

export default continuedProjectsData;