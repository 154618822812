import React from 'react'
import client1 from "../../assets/clients-1.png";
import client2 from "../../assets/clients-2.png";
import client3 from "../../assets/clients-3.png";
import client4 from "../../assets/clients-4.png";
import client5 from "../../assets/clients-5.png";
import client6 from "../../assets/clients-6.png";

export default function HomeReferance() {
    return (
        <section className="uk-section">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-6@l grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-center uk-padding">
                        <img src={client1} alt="client-1" data-uk-img="loading:lazy;" />
                    </div>
                    <div className="grid-border uk-flex uk-flex-center uk-padding">
                        <img src={client2} alt="client-2" data-uk-img="loading:lazy;" />
                    </div>
                    <div className="grid-border uk-flex uk-flex-center uk-padding">
                        <img src={client3} alt="client-3" data-uk-img="loading:lazy;" />
                    </div>
                    <div className="grid-border uk-flex uk-flex-center uk-padding">
                        <img src={client4} alt="client-4" data-uk-img="loading:lazy;" />
                    </div>
                    <div className="grid-border uk-flex uk-flex-center uk-padding">
                        <img src={client5} alt="client-5" data-uk-img="loading:lazy;" />
                    </div>
                    <div className="grid-border uk-flex uk-flex-center uk-padding">
                        <img src={client6} alt="client-6" data-uk-img="loading:lazy;" />
                    </div>
                </div>
            </div>
        </section>
    )
}
