import React from 'react'
import aboutPolicy from "../../assets/about-mission.jpg";

export default function AboutMission() {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center uk-flex-last">
                        <div className="uk-padding">
                            <div className="uk-h2 uk-text-uppercase uk-margin-small uk-light uk-text-bold">MİSYON ve VİZYONUMUZ</div>
                            <p className="uk-margin-small uk-light">1996 yılında kurulan firmamız anahtar teslim kaba inşaat alanında bir çok projede faaliyet göstermiş olup kendi konut projelerini üretmeye başlamıştır. sürekli değişen ve yenilenen inşaat sektöründe, teknolojik bilgi birikimimiz, güçlü sermaye yapımız, modern mimari anlayışımız, deneyimli teknik ve idari kadromuzla birlikte nitelikli projelere imza atmak temel prensibimizdir.</p>
                            <p className="uk-margin-small uk-light uk-margin-remove-bottom">Ürettiğimiz her işte planlama, organizasyon ve proje aşamasına maksimum özeni göstermekteyiz. Mimarlarımızdan mühendislerimize ve pazarlama ekibimize herkesin insana değer veren bir bakış açısıyla kaliteye odaklanması ve işini severek yapması bizleri bir araya getiren ortak değerlerdir.</p>
                        </div>
                    </div>
                    <div className="grid-border uk-flex-first">
                        <img src={aboutPolicy} alt="" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-1"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
